import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '@clerk/clerk-react';

const FileUpload = ({ onFileUpload, setChats, setcontentopen, contentopen }) => {
  const { isLoaded, userId } = useAuth();
  const [subscription, setSubscription] = useState('');
  const [files, setFiles] = useState([]);
  const [filename, setFilename] = useState('')
  const [filetext, setFileText] = useState('')
  const [loading, setLoading] = useState(false)
  const handlesubscription = async () => {
    if (isLoaded) {
      try {
        const response = await fetch('https://notesusersmanagement.vercel.app/get-clerk-metadata', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId }),
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        if (data.privateMetadata.subscription_status === "PRO") {
          setSubscription("PRO");
          const response = await fetch('https://notesusersmanagement.vercel.app/get-pro-files', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId }),
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
          if (data.filenames) {
            setSubscription("PRO");
            setFiles(data.filenames.map(id => ({ name: `${id.substring(0, id.lastIndexOf('_', id.lastIndexOf('_') - 1))}${id.substring(id.lastIndexOf('.'))}`, value: `${id.substring(0, id.lastIndexOf('_', id.lastIndexOf('_') - 1))}${id.substring(id.lastIndexOf('.'))}` })));
          }
        }
        else {
          setSubscription("FREE");
          setFiles([
            { name: "The Hare and the Tortoise", value: "file1.txt" },
            { name: "Little Red Riding Hood", value: "file2.txt" },
            { name: "Farmer and His Sons", value: "file3.txt" },
          ]);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    handlesubscription();
    // eslint-disable-next-line
  }, [isLoaded, contentopen]);

  useEffect(() => {
    if (filename !== '') {
      fetchChatHistory();
    }
    // eslint-disable-next-line
  }, [filename]);

  const fetchChatHistory = async () => {
    if (filename) {
      try {
        const response = await axios.get(`https://notesusersmanagement.vercel.app/chats/${userId}/${filename}`);
        if (response) {
          setChats(response.data);
        }
        // console.log(response.data);
      } catch (error) {
        console.error('Error fetching chat history: ', error);
      }
    }
  };

  const onSelect01 = async (value) => {
    setLoading(true)
    if (subscription === "FREE") {
      fetch(`/files/${value}`)
        .then(response => response.blob())
        .then(async (blob) => {
          const file = new File([blob], value, { type: blob.type });
          setFilename(file.name)
          const formData = new FormData();
          formData.append('file', file);
          try {
            const response = await axios.post('https://notesgptquestionanswers.vercel.app/api/upload', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            setLoading(false)
            setFileText(response.data.text)
            onFileUpload(response.data.text, file.name);
          } catch (error) {
            console.error('Error uploading file: ', error);
          }
        });
    }
    if (subscription === "PRO") {
      const dotIndex = value.lastIndexOf('.');
      const baseName = value.substring(0, dotIndex);
      const extension = value.substring(dotIndex);

      const fname = `${baseName}_${userId}${extension}`

      setFilename(value)
      try {
        const response = await axios.post('https://notesusersmanagement.vercel.app/get-text', {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          body: JSON.stringify({ fname }),
        });
        setLoading(false)
        setFileText(response.data.text)
        onFileUpload(response.data.text, value);
      } catch (error) {
        console.error('Error uploading file: ', error);
      }
    }
  };

  const onSelect02 = async (e) => {
    setLoading(true)
    if (subscription === "PRO") {
      const file = e.target.files[0]
      setFilename(file.name)

      const dotIndex = (file.name).lastIndexOf('.');
      const baseName = (file.name).substring(0, dotIndex);
      const extension = (file.name).substring(dotIndex);

      const fname = `${baseName}_${userId}${extension}`

      const formData = new FormData();
      formData.append('file', file);
      formData.append('userId', userId);
      formData.append('fname', fname);
      try {
        const response = await axios.post('https://notesusersmanagement.vercel.app/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        });
        setLoading(false)
        setcontentopen(true)
        setFileText(response.data.text)
        onFileUpload(response.data.text, file.name);
      } catch (error) {
        console.error('Error uploading file: ', error);
      }
    }
  };

  return (
    <div>
      <div>
        {loading && (
          <p style={{ textAlign: 'center' }}>Loading...</p>
        )}
        {!filetext && (
          <div>
            {!loading && (
              <div style={{ height: '20px' }}></div>
            )}
            {files.map((file, index) => (
              <div key={index}>
                <button id='fileName' onClick={() => {
                  onSelect01(file.value)
                  setcontentopen(true)
                }
                }>{file.name}</button>
              </div>
            ))}
            {subscription === "PRO" && (
              <input id="files" style={{ display: 'none' }} type="file" onChange={onSelect02} />
            )}
          </div>
        )}
        <div>
        </div>
        {filetext && (
          <div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <button id='closefilecontent' onClick={() => {
                setFileText('');
                setcontentopen(false)
              }}>←</button>
            </div>
            <div className="document-container">
              <div className="page">{filetext}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FileUpload;
