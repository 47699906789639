import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { SignedIn, useAuth, UserButton, useUser } from '@clerk/clerk-react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Register from './components/Register';
import Login from './components/Login';
import Main from './components/Main';
import NotFound from './components/NotFound';
import Subscription from './components/Subscription';
import Success from './components/success';
import LandingPage from './components/LandingPage'
import PricingPage from './components/PricingPage';
import PrivacyPolicy from './components/PrivacyPolicy';

const App = () => {
  const { isSignedIn } = useUser();
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
  const [subscription, setSubscription] = useState('');
  const { isLoaded, userId } = useAuth()
  const location = useLocation();

  const handlesubscription = async () => {
    if (isLoaded && userId) {
      try {
        const response = await fetch('https://notesusersmanagement.vercel.app/get-clerk-metadata', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId: userId }),
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        if (data.privateMetadata.subscription_status === "PRO") {
          setSubscription("PRO");
        }
        else {
          setSubscription("FREE");
        }
      } catch (error) {
        console.error(error);
      }
    };
  }

  useEffect(() => {
    handlesubscription()
    // eslint-disable-next-line
  }, [isLoaded])

  const navbarRoutes = ['/', '/dashboard', '/subscribe', '/pricing'];

  return (
    <div>
      {navbarRoutes.includes(location.pathname) && (
        <header>
          <nav>
            {isSignedIn ? (
              <>
                <a href={'/dashboard'}>
                  <h3 id='notetext'>NotesGPT</h3>
                </a>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {subscription === "FREE" && (
                    <div>
                      <a className="tilted-parallelogramnp" href="/subscribe" target="_blank" rel="noopener noreferrer">
                        <h1 style={{ fontSize: 10, transform: "skew(10deg)", fontFamily: "Nunito Sans", fontWeight: 800, margin: 4, fontStyle: 'italic', color: '#000000', verticalAlign: 'bottom' }}>SUBSCRIBE</h1>
                      </a>
                    </div>
                  )}
                  {subscription === "PRO" && (
                    <div>
                      <a className="tilted-parallelogram" href="/success" target="_blank" rel="noopener noreferrer">
                        <h1 style={{ fontSize: 10, transform: "skew(10deg)", fontFamily: "Nunito Sans", fontWeight: 800, margin: 4, fontStyle: 'italic', color: '#000000', verticalAlign: 'bottom' }}>PRO</h1>
                      </a>
                    </div>
                  )}
                  <UserButton appearance={{
                    elements: {
                      userButtonBox: {
                        color: "#efeee9",
                      },
                      userButtonAvatarBox: {
                        width: 36,
                        height: 36,
                        borderWidth: 30
                      },
                    },
                  }} />
                </div>
              </>
            ) : (
              <>
                <a href='/'>
                  <h3 id='notetext'>NotesGPT</h3>
                </a>
                <div>
                  <a href="/pricing">Pricing</a>
                  <a id='get-started' href="/login">Get Started</a>
                </div>
              </>
            )}
          </nav>
        </header>
      )}
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={
          <SignedIn>
            <Main />
          </SignedIn>
        } />
        <Route path="/subscribe" element={
          <SignedIn>
            <Elements stripe={stripePromise}>
              <Subscription />
            </Elements>
          </SignedIn>
        } />
        <Route path="/success" element={
          <SignedIn>
            <Success />
          </SignedIn>
        } />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />}/>
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;