import React, { useEffect, useState } from 'react';
import axios from 'axios';
import FileUpload from './FileUpload';
import { useAuth } from '@clerk/clerk-react';
import NGPT from "../notesgpt.png"

function Main() {
    const { isLoaded, userId } = useAuth();
    const [fileText, setFileText] = useState('');
    const [question, setQuestion] = useState('');
    const [chats, setChats] = useState([]);
    const [FileName, setfileName] = useState('');
    const [sidebaropen, setsidebaropen] = useState(true);
    const [contentopen, setcontentopen] = useState(false);
    const [subscription, setSubscription] = useState('');

    const handlesubscription = async () => {
        if (isLoaded) {
            try {
                const response = await fetch('https://notesusersmanagement.vercel.app/get-clerk-metadata', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ userId }),
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                if (data.privateMetadata.subscription_status === "PRO") {
                    setSubscription("PRO");
                }
                else {
                    setSubscription("FREE");
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    // Fetch subscription status and chat history on component mount or isLoaded change
    useEffect(() => {
        if (userId && FileName) {
            fetchChatHistory();
        }
        // eslint-disable-next-line
    }, [userId, FileName]);

    useEffect(()=>{
        handlesubscription();
        // eslint-disable-next-line
    },[isLoaded])

    const fetchChatHistory = async () => {
        if (FileName) {
            try {
                const response = await axios.get(`https://notesusersmanagement.vercel.app/chats/${userId}/${FileName}`);
                setChats(response.data);
                scrollToBottom();
            } catch (error) {
                console.error('Error fetching chat history: ', error);
            }
        }
    };

    const handleFileUpload = (text, filename) => {
        setfileName(filename)
        setFileText(text);
    };

    const handleQuestionSubmit = async (e) => {
        e.preventDefault();

        const newChat = { userId, message: question, isUser: true, documentId: FileName };
        setChats([...chats, newChat, { userId, message: "Hmmm...", isUser: false, documentId: FileName }]);
        setTimeout(()=>{
            setChats([...chats, newChat, { userId, message: "Let me think...", isUser: false, documentId: FileName }]);
        }, 1500)
        setQuestion('');
        setTimeout(()=>{
            scrollToBottom();
        }, 500)

        try {
            await axios.post('https://notesusersmanagement.vercel.app/chats', newChat);

            const response = await axios.post('https://notesgptquestionanswers.vercel.app/api/ask', {
                question,
                text: fileText
            });

            // Add document answer to the chat
            const newAnswer = { userId, message: response.data.answer, isUser: false, documentId: FileName };
            setChats([...chats, newChat, newAnswer]);
            await axios.post('https://notesusersmanagement.vercel.app/chats', newAnswer);
            scrollToBottom();
        } catch (error) {
            console.error('Error asking question: ', error);
        }
    };


    // Function to render chat messages
    const renderChatMessages = () => {
        return chats.map((chat, index) => (
            <div key={index}>
                {chat.isUser ? (
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <p style={{ textAlign: 'right', padding: '14px', backgroundColor: '#dbdad5', borderRadius: '20px', maxWidth: '70%', wordWrap: 'break-word' }}>{chat.message}</p>
                    </div>
                ) : (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '10px' }}>
                        {FileName && (
                            <img className='logo2' src={NGPT} alt="Logo" style={{ width: '30px', height: '30px', marginRight: '10px', marginTop: '10px', alignSelf: 'flex-start' }} />
                        )}
                        <p style={{ textAlign: 'left', maxWidth: '70%', wordWrap: 'break-word' }}>{chat.message}</p>
                    </div>
                )}
            </div>
        ));
    };

    const scrollToBottom = () => {
        const chatContainer = document.getElementById('chat-container');
        if (chatContainer) {
            chatContainer.scrollTop = chatContainer.scrollHeight;
        }
    };


    if (!userId) {
        return <div>Loading...</div>;
    }

    return (
        <div style={{ display: 'flex' }}>
            {/* Sidebar */}
            {sidebaropen && (
                <div className='sidebar'>
                    {!contentopen && (
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <button id='closefilecontent' onClick={() => { setsidebaropen(false) }}>←</button>
                            {subscription === "PRO" && (
                            <label htmlFor="files" className="inbtn">+</label>
                            )}
                        </div>

                    )}
                    <FileUpload onFileUpload={handleFileUpload} contentopen={contentopen} setChats={setChats} setcontentopen={setcontentopen} />
                </div>
            )}

            {/* Main Content Area */}
            {!sidebaropen && (
                <button id='opside' onClick={() => { setsidebaropen(true) }}>→</button>
            )}
            <div className="main-content" id="chat-container" style={{ overflowY: 'auto', height: 'calc(100vh - 85px)' }}>
                <div className="chat-section">
                    {renderChatMessages()}
                    <div style={{ height: '60px', width: '100%' }}></div>
                </div>
                <div className="form-container">
                    <form onSubmit={handleQuestionSubmit}>
                        <div className="input-container">
                            <input
                                id='askinp'
                                type="text"
                                value={question}
                                onChange={(e) => setQuestion(e.target.value)}
                                placeholder="Ask a question"
                                className="text-input"
                                autoComplete="off"
                                required={true}
                            />
                            <button id='subbtn' type="submit" className="send-button">
                                <img src='/send.png' alt="Send" className='send-icon' id='sendbtn' />
                            </button>
                        </div>
                    </form>
                </div>
                <div style={{ position: 'absolute', bottom: 0, height: '70px', width: sidebaropen ? ("70%") : ("100%"), overflow: 'hidden', backgroundColor: "#efeee9" }}></div>
            </div>
        </div>
    );
}

export default Main;
