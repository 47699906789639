import React, { useEffect, useState } from 'react';
import { useAuth } from '@clerk/clerk-react';
import ReactConfetti from 'react-confetti';

const Success = () => {
    const { isLoaded, userId } = useAuth();
    const [subscription, setSubscription] = useState("")
    const handlesubscription = async () => {
        if (isLoaded) {
            try {
                const response = await fetch('https://notesusersmanagement.vercel.app/get-clerk-metadata', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ userId: userId }), // Send the selected Price ID
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json(); // Wait for the JSON parsing
                if (data.privateMetadata.subscription_status === "PRO") {
                    setSubscription("PRO")
                }
                else {
                    window.location.href = '/dashboard';
                }
            } catch (error) {
                console.error(error)
            }
        }
    }
    useEffect(() => {
        handlesubscription()
        // eslint-disable-next-line
    }, [userId])
    const handleRedirect = () => {
        window.location.href = '/dashboard';
    };

    return (
        <div>
            {subscription === "PRO" && (
                <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <ReactConfetti
                        width={window.innerWidth}
                        height={window.innerHeight}
                        numberOfPieces={200}
                        recycle={false}
                        gravity={0.3}
                        wind={0}
                        opacity={1}
                        style={{ zIndex: -100 }}
                    />
                    <div style={{ textAlign: 'center', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)', padding: '60px 40px', borderRadius: '16px' }}>
                        <h2 style={{ fontSize: '40px', margin: 0 }}>Subscription Successful!</h2>
                        <p style={{ textAlign: 'left', marginTop: '4px' }}>Good Luck on your journey with NotesGPT.</p>
                        <div style={{ display: 'flex' }}>
                            <button onClick={handleRedirect} id="redirect-button">Go to App</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Success;
