import React, { useState } from 'react';
import '../styles/App.css';
import { useNavigate } from 'react-router-dom';

const PricingPage = () => {
  const [selectedPlan, setSelectedPlan] = useState('monthly');
  let navigate = useNavigate();

  const handleSubscriptionChange = (plan) => {
    setSelectedPlan(plan);
  };

  const subscribe = () => {
    navigate("/login")
  };

  return (
    <div className="subscription-container" style={{ backgroundColor: '#efeee9', color: '#36454F' }}>
      <h1>Choose Your Subscription Plan</h1>
      <div className="plan-selection">
        <div className={`plan-option ${selectedPlan === 'monthly' ? 'selected' : ''}`} onClick={() => handleSubscriptionChange('monthly')}>
          <h3>Monthly Plan</h3>
          <h2>$11.99 / month</h2>
          <ul>
            <li style={{ textAlign: "left", marginBottom: '2px', marginTop: '10px' }}>Unlimited Documents</li>
            <li style={{ textAlign: "left" }}>Unlimited Chats</li>
          </ul>
        </div>
        <div className={`plan-option ${selectedPlan === 'yearly' ? 'selected' : ''}`} onClick={() => handleSubscriptionChange('yearly')}>
          <h3>Yearly Plan</h3>
          <h2>$99.99 / year</h2>
          <ul>
            <li style={{ textAlign: "left", marginBottom: '2px', marginTop: '10px' }}>Unlimited Documents</li>
            <li style={{ textAlign: "left" }}>Unlimited Chats</li>
          </ul>
        </div>
      </div>
      <button className="subscribe-button" onClick={subscribe}>
        Subscribe Now
      </button>
    </div>
  );
};

export default PricingPage;
