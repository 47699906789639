import React from 'react';
import { SignUp } from '@clerk/clerk-react';

const Register = () => (
    <div style={{ height: '100vh', backgroundColor: '#36454F', display: 'flex', alignItems:'center', justifyContent: 'center' }}>
        <SignUp signInUrl='/login' forceRedirectUrl='/dashboard' appearance={{ variables: { fontFamily: 'Nunito Sans, sans-serif' } }} />
    </div>
);

export default Register;
