import React, { useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useAuth, useUser } from '@clerk/clerk-react';

const Subscription = () => {
    const { userId } = useAuth()
    const [loading, setLoading] = useState(false);
    const [pid, setPid] = useState('price_1Pca4MG9Afzccpu4J0J5w8MB');
    const stripe = useStripe();
    const { user } = useUser();
    const email = user.emailAddresses.find(email => email.id === user.primaryEmailAddressId)?.emailAddress;

    const handleSubscribe = async (priceId) => {
        setLoading(true);
        try {
          // Create a Checkout Session on your server
          const response = await fetch('https://notesusersmanagement.vercel.app/create-checkout-session', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ priceId, email, userId }), // Send the selected Price ID
          });
      
          const session = await response.json();
      
          // Redirect to Checkout
          const result = await stripe.redirectToCheckout({
            sessionId: session.id,
          });
      
          if (result.error) {
            console.error(result.error.message);
            setLoading(false);
          }
        } catch (error) {
          console.error('Error creating checkout session:', error.message);
          setLoading(false);
        }
      };      

    return (
      <div className="subscription-container" style={{ backgroundColor: '#efeee9', color: '#36454F' }}>
      <h1>Choose Your Subscription Plan</h1>
      <div className="plan-selection">
        <div className={`plan-option ${pid === 'price_1Pca4MG9Afzccpu4J0J5w8MB' ? 'selected' : ''}`} onClick={() => setPid('price_1Pca4MG9Afzccpu4J0J5w8MB')}>
          <h3>Monthly Plan</h3>
          <h2>$11.99 / month</h2>
          <ul>
            <li style={{ textAlign: "left", marginBottom: '2px', marginTop: '10px' }}>Unlimited Documents</li>
            <li style={{ textAlign: "left" }}>Unlimited Chats</li>
          </ul>
        </div>
        <div className={`plan-option ${pid === 'price_1Pca5QG9Afzccpu46HjVcq0H' ? 'selected' : ''}`} onClick={() => setPid('price_1Pca5QG9Afzccpu46HjVcq0H')}>
          <h3>Yearly Plan</h3>
          <h2>$99.99 / year</h2>
          <ul>
            <li style={{ textAlign: "left", marginBottom: '2px', marginTop: '10px' }}>Unlimited Documents</li>
            <li style={{ textAlign: "left" }}>Unlimited Chats</li>
          </ul>
        </div>
      </div>
      <button className="subscribe-button" onClick={() => handleSubscribe(pid)} disabled={loading}>
        Subscribe Now
      </button>
    </div>
    );
};

export default Subscription;
