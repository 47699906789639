import React from 'react';
import '../styles/App.css';
import intro from '../videos/intro.mov';
import Section from './section';

function LandingPage() {
  const sections = [
    {
      title: 'Research Papers',
      description: "Need to write a paper or conduct a thorough review? NotesGPT helps you uncover key insights and compare diverse perspectives across various studies."
    },
    {
      title: 'User Manuals',
      description: 'Lost in the instructions? NotesGPT assists you in finding the steps and guidance you need to use your products correctly.'
    },
    {
      title: 'Legal Documents',
      description: 'Legal jargon can be complex. NotesGPT simplifies it by helping you find clear answers and understand your legal documents with ease.'
    },
    {
      title: 'Books',
      description: 'Reading alone can be challenging. Let NotesGPT be your reading companion, helping you summarize chapters and clarify any concepts you might have missed.'
    },
    {
      title: 'Financial Documents',
      description: 'Financial documents can be overwhelming. NotesGPT helps you navigate forms like 10-Ks, 10-Qs, and more, making sense of the numbers and details.'
    },
    {
      title: 'Course Materials',
      description: 'Studying complex subjects? NotesGPT is your study buddy, simplifying concepts, summarizing lessons, and highlighting key points to remember.'
    }
  ];

  return (
    <div className="App">
      <section className="hero">
        <h1 style={{ marginBottom: 0 }}>Upload Your Documents and</h1>
        <h1 style={{ marginTop: 0 }}>Get Answers Instantly using AI</h1>
        <p style={{ marginBottom: 2 }}>Our AI-powered app allows you to upload PDF or TXT files,</p>
        <p style={{ marginTop: 0 }}>and ask questions to get precise answers in real-time.</p>
        <button onClick={() => { window.location.href = '/login' }}>Get Started For Free</button>
      </section>
      <div style={{ display: 'flex', justifyContent: 'center', borderRadius: '10px' }}>
        <video style={{ borderRadius: '14px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)' }} width="60%" controls>
          <source src={intro} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <section id="features" className="features">
        <h2>CORE FEATURES</h2>
        <div id='featurescontainer'>
          <div className="feature-item" style={{ flex: 1 }}>
            <h3>AI-Powered Q&A</h3>
            <p style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>Get accurate answers to your questions from uploaded documents using advanced AI algorithms.</p>
          </div>
          <div className="feature-item" style={{ flex: 1 }}>
            <h3>Multi-Language Chat</h3>
            <p style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>Ask questions and get answers in different languages.</p>
          </div>
          <div className="feature-item" style={{ flex: 1 }}>
            <h3>Real-Time Processing</h3>
            <p style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>Receive answers in real-time, no waiting required.</p>
          </div>
          <div className="feature-item" style={{ flex: 1 }}>
            <h3>Easy Navigation</h3>
            <p style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>Navigate through your uploaded documents with ease.</p>
          </div>
        </div>
      </section>
      <div className="app">
        <h1 className='h1n'>FIT FOR ALL YOUR NEEDS</h1>
        <div className="sections">
          {sections.map((section, index) => (
            <Section
              key={index}
              title={section.title}
              description={section.description}
            />
          ))}
        </div>
      </div>
      <section id="how-it-works" className="how-it-works">
        <h2>How It Works</h2>
        <div className="step-item">
          <h3>Step 1: Upload</h3>
          <p style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>Select and upload your PDF or TXT document.</p>
        </div>
        <div className="step-item">
          <h3>Step 2: Ask Questions</h3>
          <p style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>Type in your questions related to the content of the document.</p>
        </div>
        <div className="step-item">
          <h3>Step 3: Get Answers</h3>
          <p style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>Receive precise answers from our AI instantly.</p>
        </div>
      </section>
      <button className='gsbtn'>Get Started For Free</button>
      <div style={{ height: '40px' }}></div>
      <footer>
        <p style={{ textAlign: 'left', marginBottom: 1 }}>Contact:</p>
        <p style={{ textAlign: 'left', marginTop: 1 }}>byte9962@gmail.com</p>
      </footer>
    </div>
  );
}

export default LandingPage;
