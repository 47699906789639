import React from 'react';
import { SignIn } from '@clerk/clerk-react';

const Login = () => (
  <div style={{height: '100vh', backgroundColor: '#36454F'}}>
    <h1 id='notetext2'>NotesGPT</h1>
    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
      <SignIn signUpUrl='/register' forceRedirectUrl='/dashboard' routing='hash' appearance={{ variables: { fontFamily: 'Nunito Sans, sans-serif' } }} />
    </div>
  </div>
);

export default Login;
