import React from 'react';

const NotFound = () => {
  const containerStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#000000',
    color: '#efeee9',
    fontFamily: 'Arial, sans-serif',
  };

  const textContainerStyles = {
    display: 'flex',
    alignItems: 'center',
  };

  const dividerStyles = {
    width: '1px',
    height: '50px',
    backgroundColor: '#bdbbb6',
    margin: '0 20px',
  };

  const textStyles = {
    fontSize: '20px',
    fontFamily: 'Nunito Sans',
  };

  const errorCodeStyles = {
    fontSize: '30px',
    fontFamily: 'Nunito Sans',
  };

  return (
    <div style={containerStyles}>
      <div style={textContainerStyles}>
        <div style={errorCodeStyles}>404</div>
        <div style={dividerStyles}></div>
        <div style={textStyles}>Page not Found.</div>
      </div>
    </div>
  );
};

export default NotFound;
